.sidebar {
  width: 200px;
  height: calc(100vh - 90px);
  background-color: #212529;
  color: #ffffff;
  padding: 20px;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar li:hover {
  color: #61dafb;
}

.sidebar a {
  color: inherit;
  text-decoration: none;
}

.sidebar a:hover {
  color: #61dafb; /* Change color on hover */
}
