.nav{
  background-color: rgb(235, 235, 197);
  height: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.rightDiv{
 margin-right: 20px;
 text-decoration: none;
}
