.flex{
  display: flex;
}

.card{
  margin: 20px;
  border: 1px solid gray;
  padding: 12px;
  width: 100%;
  border-radius: 10px;
}
.apart{
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.heading{
  font-size: 26px;
}

.searchContainer {
  position: relative;
  display: inline-block;
}

.searchInput {
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  width: 300px;
}

.searchButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  /* background-color: #007bff; */
  border: none;
  color: white;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}
