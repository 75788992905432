.banner{
  padding: 6px;
  background-color: rgb(41, 180, 163);
}

.lt{
  margin-left: 10px;
  color: white;
  font-size: 30px;
}

.hed{
  margin: 0;
  color: rgb(30, 148, 202);
  font-size: 22px;
}


.flex{
  display: flex;
}