.body {
  padding: 10px 10vw;
}

.title {
  font-weight: 600;
}

.flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.ca {
  background-color: rgb(240, 240, 240);
  padding: 10px 20px;
  border: 1px solid  #d7e359;
  border-radius: 12px;
  color: green;
  font-size: 1.2em;
  font-weight: 600;
}


.post {
  color: #3575e2;
}

.cls {
  font-weight: 500;
  color: #ff0000;
}

.card {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px);
  background-color: #fff;
  border: 1px solid  #d7e359;
  border-radius: 12px;
}
.card > h1 {
  margin: 10px;
  padding: 0px;
}
.card > h2 {
  margin: 10px;
  padding: 0px;
}

.dashComp {
  margin: 12px;
  padding: 12px 16px 12px 16px;
  width: 20%;
  max-width: 40%;
  border: 1px solid #d7e359;
  border-radius: 6px;
}


.dashComp h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
}
.dashComp h4 {
  margin: 0px;
  font-size: 16px;
  font-weight: 300;
}


.dashClsd {
  margin: 12px;
  padding: 12px 16px 12px 16px;
  width: 20%;
  max-width: 40%;
  border-radius: 6px;
  border: 1px solid red;
}

.dashClsd h3 {
  color: red;
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
}
