.flex{
  display: flex;
}

.dash{
  margin: 20px 36px 10px 36px;
  padding: 12px 20px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 12px;
}
.dash h1{
  margin: 0;
  margin-bottom: 20px;
}

.dashComp{
  padding: 12px 16px 12px 16px;
  margin-bottom: 20px;
  width: 20%;
  max-width: 40%;
}

.dashComp h3{
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
}
.dashComp h4{
  margin: 0px;
  font-size: 16px;
  font-weight: 300;
}