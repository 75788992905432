.body{
  display: flex;
  justify-content: center;
}

.flex{
  display: flex;
  gap:10%
}

.flex > div{
  flex: 1;
}

.flex > div > input{
  border-radius: 8px;
}

.spaceBetween {
  justify-content: space-between;
}

.card {
  min-width: 500px;
  width: 800px;
  margin: 20px;
  padding: 20px 10px 20px 20px;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.job-posting-page {
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}


.form-group {
  margin-bottom: 20px;
}

.label {
  font-weight: 400;
  font-size: 1.2em;
}

.textarea {
  width: 100%;
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}






.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.formWidth {
  width: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.form-group {
  margin-bottom: 20px;
}


.textArea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
}

.btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}
