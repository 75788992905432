.bg{
  background-color: rgb(240, 240, 240);
}

.container{
  display: flex;
  
}

.sidebar {
  width: 200px;
  min-height: 80vh;
  background-color: #212529;
  color: #ffffff;
  padding: 10px;
  left: 0;
  overflow-y: auto;
}

.card{
  flex: 1;
  margin:26px;
  background-color: white;
  border-radius: 6px;
  border : 1px solid rgb(191, 191, 191);
}

.heading{
  padding-top: 20px;
  padding-bottom:16px ;
  padding-left: 50px;
  font-size: 25px;
  font-weight: bold;
  border: 1px solid rgb(223, 223, 223);
}

.hr{
  padding: 12px;
  padding-left: 50px;
  height: 30px;
  font-size: 17px;
  font-weight: 800;
  background-color: #eeeeee;
  border: 1px solid rgb(188, 188, 188);
}

.body{
  max-height: 74vh;
  overflow-y: scroll;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar li:hover {
  color: #61dafb;
}

.sidebar a {
  color: inherit;
  text-decoration: none;
}

.sidebar a:hover {
  color: #61dafb;
}
