.reg{
    background-color:white;
    box-shadow: 0px 2px 10px rgb(134, 134, 134);
    padding-bottom: 50px;
}

.body{
    background-color:rgb(244,248,253);
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.options{
    padding: 12px !important;
    margin-right: 20px !important;
}

.options:hover {
    background-color: rgb(55, 138, 227) !important; 
    color: white;
  }