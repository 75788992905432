.body {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c_g {
  color: #80dc5c;
}

.hed {
  font-size: 3rem;
  word-spacing: 10px;
  font-weight: 700;
  line-height: 1;
}

.des {
  font-size: 1.7em;
  color: #555;
}

.flex {
  display: flex;
  justify-content: center;
}

.flx_srh {
  width: 100%;
  display: flex;
  margin-top: 30px;
}

.childC {
  align-self: flex-end;
}

.blueBg {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 200px;
  background-color: #481818;
}

.flex_hed {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 1800px;
  align-items: flex-start;
}

.flex_body {
  margin: 20px 20px 0 20px;
  width: 100%;
  display: flex;
  max-width: 1800px;
  flex-wrap: wrap-reverse;
  gap: 40px;
}

.filterCard {
  flex: 3;
  border: 1px solid rgb(184, 184, 184);
  border-radius: 12px;
  padding: 20px;
  font-size: 26px;
  min-width: 260px;
  background-color: white;
}

.jobCards {
  flex: 17;
}

.jobCard {
  min-width: 800px;
  min-height: 80vh;
  align-self: flex-start;
  border-radius: 10px;
}

.card {
  min-width: 250px;
  align-self: flex-start;
  background-color: white;
  border-radius: 10px;
}
.cardHed {
  border-radius: 10px 10px 0px 0px;
  padding: 6px 20px;
  background-color: #efd1c7;
}
.cardBody {
  padding: 20px 22px 30px 22px;
}

.apart {
  margin-left: 120px;
  display: flex;
  justify-content: space-between;
}

.noJob {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  font-size: 22px;
}

.center {
  display: flex;
  justify-content: center;
}

.searchInput {
  padding: 0 10px;
  height: 100%;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: none;
}

.count {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.btn1 {
  display: flex;
  padding: 2px 20px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 2px solid #218477;
  border-radius: 6px;
  size: 20px;
  color: #218477;
  cursor: pointer;
}

.btn2 {
  max-width: 200px;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 20px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border: 2px solid #f24a0d;
  border-radius: 6px;
  size: 20px;
  color: #844221;
  cursor: pointer;
}

.searchBox {
  flex: 4;
  font-family: Arial, sans-serif;
  border: 1px solid rgb(228, 228, 228);
  padding: 10px 6px;
  display: flex;
  align-items: center;
}

.prefixLabel {
  color: #555;
}

.searchButton {
  flex: 1;
  background-color: #3575e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s;
}

.searchButton:hover {
  transform: scale(1.1);
}

.filterCard > ul > .f_label {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
}

.filterCard > .f_ {
  font-weight: 600;
  font-size: 22px;
}

.radioButton {
  /* appearance: none; Remove default radio button styling */
  /* -webkit-appearance: none; For Safari */
  width: 20px; /* Size of the radio button */
  height: 20px; /* Size of the radio button */
  /* border: 4px solid rgb(176, 176, 176); Border color of the radio button */
  /* border-radius: 50%; Make it circular */
  /* margin-right: 10px; Space between radio button and text */
  /* position: relative; Positioning for the inner circle */
}

.listItem> label{
  font-size: 20px;
}