.bg{
  background-color: rgb(240, 240, 240);
}

.container{
  display: flex;
  
}

.headingRight{
  right:0;
  position: absolute;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 450;
  border: 1px solid rgb(223, 223, 223);
}

.card{
  flex: 1;
  margin:26px;
  background-color: white;
  border-radius: 6px;
  border : 1px solid rgb(191, 191, 191);
}

.heading{
  padding-top: 20px;
  padding-bottom:16px ;
  padding-left: 10px;
  font-size: 25px;
  font-weight: bold;
  border: 1px solid rgb(223, 223, 223);
}

.hr{
  padding: 12px;
  padding-left: 50px;
  height: 30px;
  font-size: 17px;
  font-weight: 800;
  background-color: #eeeeee;
  border: 1px solid rgb(188, 188, 188);
}

.body{
  max-height: 74vh;
  overflow-y: scroll;
}

