.horizontalCard {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 22px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.horizontalCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.cardImage {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  align-self: flex-start;
}

.cardContent {
  flex: 1;
  font-size: 16px;
  padding: 0px 0px 0px 10px;
}

.instNm{
  font-size: 1rem;
  color: #777;
}

.cardTitle {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.flex{
  display: flex;
}

.rowMembers {
  margin-top: 10px;
  display: flex;
  width: 600px;
  justify-content: flex-start;
  font-size: 1rem;
}

.member {
  margin-right: 30px; 
}

.cardButton {
  padding: 10px 0px;
  background-color: transparent;
  color: #196cab;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .cardButton:hover {
  background-color: #0056b3;
} */

.btn{
  color: rgb(108, 176, 255);
}
.btn:hover{
  color: rgb(11, 120, 245);
}