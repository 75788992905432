.body{
  background-color: #f5f5f5;
  padding: 2%;
}

.flex{
  display: flex;
  justify-content: space-between;
}

.img{
  height: 100px;
}

.left{
  flex: 3;
  background-color: rgb(228, 228, 228);
  padding: 20px;
  height: 802px;
}

ul{
  list-style-type: none;
  padding: 0px;
}
li{
  margin-bottom: 5px;
}

h1{
 font: normal 26px LiberationSans,Helvetica,Arial,sans-serif;
 color: rgb(1, 59, 97);
 margin: 16px 0px 4px 0px;
}
h2{
  font: normal 21px LiberationSans,Helvetica,Arial,sans-serif;
  color: rgb(1, 59, 97);
  margin: 16px 0px 4px 0px;
 }

.dt{
  font-size: 11px;
}

.right{
  flex: 7;
  padding: 20px;
  height: 100%;
}

p{
font:  normal 14px LiberationSans,Helvetica,Arial,sans-serif;
color: rgb(75, 75, 75);
}

h5{
  margin: 0px;
}

.desc{
  color: black;
  font-size: 14px;
}
/* .spaceBetween {
  justify-content: space-between;
} */


/* .noJobs {
  margin: auto; 
  font-size: 22px;
  display: flex;
  margin-top: 40px;
  justify-content:space-around; 
  align-items: center;
  height: 70vh;
} */


.heading{
  font-size: 26px;
}

.all{
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: 100%;
}


.card{
  width: 595px;
  height: 842px;
  background-color: #ffffff;
  /* overflow-y: auto; */
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgb(124, 124, 124);
}

.introCard {
  margin-left: 30px;
  margin-right: 30px;
  width: 300px;
}

.introHr {
  font-size: 20px;
  font-weight: 700;
}
.introtext {
  font-size: 14px;
}

.introtext{
  font-weight: bold;
  font-size: 50px;
}

.avatar{
  min-width: 100px;
  min-height: 100px;
  max-width: 200px;
  border-radius: 30px;
  border: aqua solid 1px;
}


.aboutHr {
  color: rgb(167, 155, 155);
  font-size: 18px;
  font-weight: 400;
}
