.loginCon{
    background-color:white;
    box-shadow: 0px 4px 14px rgb(122, 122, 122);
}

.body{
    background-color:rgb(244,248,253);
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
