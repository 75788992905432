@tailwind base;
@tailwind components;
@tailwind utilities;

/* About us Page Css */
.hover-line-effect {
  position: relative;
  overflow: hidden;
}

.diagonal-line-effect {
  position: relative;
  overflow: hidden;
}

.diagonal-line-effect::before {
  content: "";
  position: absolute;
  top: -100%;
  left: -100%;
  width: 140%;
  height: 30px;
  background-color: #7ED958;
  transform: rotate(45deg);
  transition: all 3s ease-in-out;
}

.diagonal-line-effect:hover::before {
  top: 100%;
  left: 100%;
}
