.body {
  background-color: #f5f5f5;
  min-height: 90vh;
  padding: 0 3vw;
}

.right {
  position: absolute;
  margin-right: 20px;
  right: 0px;
}

.flx {
  display: flex;
  justify-content: space-between;
  gap: 4%;
  flex-wrap: wrap;
  margin:  10px;
}

.left {
  flex: 7;
  min-width: 400px;
}

.right {
  flex: 4;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgb(124, 124, 124);
}

.half {
  flex: 1;
  max-width: 300px;
}

.introHr {
  font-size: 20px;
  font-weight: 700;
}
.introtext {
  font-size: 14px;
}

.introtext {
  font-weight: bold;
  font-size: 50px;
}

.input {
  margin-top: 6px;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 10px;
}

.flx_ver{
  display: flex;
  flex-direction: column;
  gap:16px;
}

.avatar {
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 3px solid #7ED958;
  align-self: center;
}

.avatar_btn{
  padding: 6px;
  border-radius: 12px;
  background-color: #EDEDED;
  text-align: center;
  font-weight: 500;
}

.aboutHr {
  color: rgb(167, 155, 155);
  font-size: 18px;
  font-weight: 400;
}

.pd {
  margin: 10px;
}
