.reg{
    background-color:white;
    box-shadow: 0px 5px 10px rgb(134, 134, 134);
}

.body{
    background-color:rgb(244,248,253);
    height: 90vh;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.left{
    text-align: left;
    font-weight: 600;
    margin-bottom: 5px;
}