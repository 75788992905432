.reg{
    width: 200px;
    background-color:white;
    box-shadow: 0px 2px 10px rgb(134, 134, 134);
    padding-bottom: 50px;
}

.body{
    background-color:rgb(244,248,253);
    height: 90vh;
}
.body h2{
    margin: 0 auto; 
    padding-top:50px ;
    padding-bottom:40px ;
    width: fit-content; 
}

.card{
    padding : 50px 0px 0px 0px;
    display: flex;
    justify-content: space-evenly;
}
.card img{
    max-width: 60px;
}

.options{
    padding: 12px !important;
    margin-right: 20px !important;
}

.options:hover {
    background-color: rgb(55, 138, 227) !important; 
    color: white;
  }