.noJobs{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  font-size: 22px;
}

.card {
  margin: 10px;
  padding: 20px;
  width: calc(100% - 20px);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 12px;
}